import React, { Component } from "react";
import logo from "./assets/cloud.png";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      subscribed: false
    };
  }

  handleSubmit = e => {
    if (!this.state.email || this.state.subscribed) {
      e.preventDefault();
      return;
    }
    fetch(
      "https://cors-anywhere.herokuapp.com/https://script.google.com/macros/s/AKfycbx2PBPp3QtttrPUKde2Bs8Kgq8rrFfumf3fuubGgtXuO8d_aQ/exec",
      {
        crossDomain: true,
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": true,
          mode: "no-cors",
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email: this.state.email })
      }
    );
    this.setState({ subscribed: true });
    e.preventDefault();
  };

  handleChange = e => {
    this.setState({ email: e.target.value });
  };

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p className="modern-sans top-welcome">WELCOME TO THE</p>
          <p className="playfair-display">Founders Cloud</p>
          <p className="modern-sans bottom-welcome">
            WE'RE LOOKING FORWARD TO HOSTING HIGH QUALITY TECH ENTREPRENEURSHIP
            PROGRAMMING STARTING EARLY 2019.
          </p>
          <p className="modern-sans bottom-welcome">
            SUBSCRIBE BELOW FOR UPDATES.
          </p>
          {!this.state.subscribed ? (
            <form className="submit-container" onSubmit={this.handleSubmit}>
              <input
                type="text"
                className="email-text-input"
                placeholder="founder@hooli.xyz"
                required={true}
                onChange={this.handleChange}
              />
              <input
                type="submit"
                value="Subscribe"
                className="email-submit-button"
              />
            </form>
          ) : (
            <p className="modern-sans bottom-welcome">
              THANK YOU FOR SUBSCRIBING.
            </p>
          )}
        </header>
      </div>
    );
  }
}

export default App;
